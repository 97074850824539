nav ul {
    margin: 0;
    padding: 0.125rem;
    list-style: none;
    font-size: 1.25rem;
    border: 0.25rem solid var(--color-black);
    background-color: var(--color-red);
  }
  
  @media (min-width: 540px) {
    nav ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
  }
  
  nav ul li {
    margin: 0.375rem 0.375rem 0.5rem;
    text-align: center;
  }
  
  @media (min-width: 540px) {
    nav ul li {
      flex: 1 0 45%;
    }
  }
  
  @media (min-width: 600px) {
    nav ul li {
      flex: 1 0 30%;
    }
  }
  
  @media (min-width: 940px) {
    nav ul li {
      flex: 1 0 auto;
    }
  }
  
  nav ul a {
    position: relative;
    display: block;
    padding: 0.5em;
    color: var(--color-white);
    font-size: 1.25rem;
    font-weight: var(--font-weight-semibold);
    border: 0.1875rem solid var(--color-red);
    border-radius: 2em;
    text-decoration: none;
    padding: 0.25em;
  }
  
  nav ul a:hover {
    background-color: var(--color-dark-red);
    border-color: var(--color-black);
  }
  
  nav ul a:focus {
    background-color: var(--color-dark-red);
  }
  
  
  nav {
    position: relative;
    width: 100%;
    z-index: 1000;
  }
  
  .nav-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 55rem;
    margin: 0 auto;
  }
  
  /* Style sticky pour la navigation */
  nav.sticky {
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    max-width: 65rem;
    z-index: 1000;
  }
  
  /* Style pour les éléments de la liste de navigation */
  nav ul {
    margin: 0;
    padding: 0.125rem;
    list-style: none;
    font-size: 1.25rem;
    border: 0.25rem solid var(--color-black);
    background-color: var(--color-red);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  nav ul li {
    flex: 1 0 22%; /* 4 items in the first row */
    margin: 0.375rem;
    text-align: center;
  }
  
  nav ul a {
    display: block;
    padding: 0.5em;
    color: var(--color-white);
    font-size: 1.25rem;
    font-weight: var(--font-weight-semibold);
    border: 0.1875rem solid var(--color-red);
    border-radius: 2em;
    text-decoration: none;
    text-align: center;
  }
  
  nav ul a:hover {
    background-color: var(--color-dark-red);
    border-color: var(--color-black);
  }
  
  .menu {
    display: none;
    font-size: 2rem;
    background: none;
    border: none;
    color: var(--color-white);
    cursor: pointer;
    transition: transform 0.3s ease-in-out;
  }
  

  
  /* Afficher le bouton burger uniquement sur les petits écrans */
  @media (max-width: 768px) {
    .burger-menu {
      font-size: 1.5rem;
      background-color: white !important;
      border: 2px solid black !important;
      color: var(--color-black) !important; 
      cursor: pointer;
      transition: transform 0.3s ease-in-out;
      padding: 0.25rem !important;
      border-radius: 0.375rem !important;
      z-index: 1001 !important; /* S'assurer que le bouton soit au-dessus des autres éléments */
    }
  
    .nav-links {
      display: none;
      flex-direction: column;
      width: 100%;
      background-color: var(--color-red);
      position: absolute;
      top: 100%;
      left: 0;
      z-index: 999;
    }
  
    .nav-links.open {
      display: flex;
    }

    nav ul li {
      flex: 1 0 45%;
    }
  
    .nav-links li {
      margin: 0.5rem 0;
    }
  
    .menu {
      background-color: transparent;
      border: none;
      cursor: pointer;
      display: flex;
      padding: 0;
    }
  }
  
  /* Style pour le menu burger */
  
  
  .line {
    fill: none;
    stroke: black;
    stroke-width: 6;
    transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
      stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
  }
  
  .line1 {
    stroke-dasharray: 60 207;
    stroke-width: 6;
  }
  
  .line2 {
    stroke-dasharray: 60 60;
    stroke-width: 6;
  }
  
  .line3 {
    stroke-dasharray: 60 207;
    stroke-width: 6;
  }
  
  .opened .line1 {
    stroke-dasharray: 90 207;
    stroke-dashoffset: -134;
  }
  
  .opened .line2 {
    stroke-dasharray: 1 60;
    stroke-dashoffset: -30;
  }
  
  .opened .line3 {
    stroke-dasharray: 90 207;
    stroke-dashoffset: -134;
  }
  