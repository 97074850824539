.projects-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
}

.project-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 0.25rem solid var(--color-black);
  background-color: var(--color-white);
  padding: 1.5rem;
  text-align: center;
  box-shadow: 0.5rem 0.5rem rgba(132, 81, 61, 0.35);
  transition: transform 0.2s;
}

.project-card:hover {
  transform: translateY(-5px);
}

.project-image {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  border: 2px solid var(--color-dark-blue);
  margin-bottom: 1rem;
}

.technologies-icons {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin: 1rem 0;
}

img[src*="cloudinary"] {
  border-radius: 20px;
}

.technologies-icons svg,
.technologies-icons img {
  color: var(--color-dark-blue);
  transition: transform 0.2s;
}

.technologies-icons svg:hover {
  transform: scale(1.2);
}

.tech-icon {
  width: 40px;
  height: auto;
  margin: 0 10px;
  transition: transform 0.2s;
}

.tech-icon:hover {
  transform: scale(1.2);
}

@media (max-width: 768px) {
  .projects-grid {
    grid-template-columns: 1fr;
  }

  .project-card {
    padding: 1rem;
  }
}

.technologies-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 1rem 0;
}

.tech-category h4 {
  margin-bottom: 0.5rem;
  color: #666;
  font-size: 0.9rem;
}

.technologies-icons {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: center;
}

.tech-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}

.tech-name {
  font-size: 0.8rem;
  text-align: center;
}

.tech-icon {
  width: 2em;
  height: 2em;
}
